/** @jsx jsx */
import { jsx, Box } from 'theme-ui';
import React, { useCallback, useState } from 'react';
import { getTranslate } from 'react-localize-redux';
import { useFormikContext } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import Spinner from '../components/Spinner';
import * as yup from 'yup';
import * as api from '../utils/api';
import Button from '../components/Button';
import FormField from '../components/FormField';

export default ({ serviceCode }) => {
  const localize = useSelector(state => state.localize);
  const translate = getTranslate(localize);
  const [showButton, setShowButton] = useState(false);
  const {
    values,
    setFieldValue,
    setFieldError,
    setFieldTouched,
    isSubmitting,
  } = useFormikContext();
  const { code } = values;

  const onInputChange = useCallback(
    e => {
      const { value } = e.target;
      setFieldValue('code', value.toUpperCase().replace(/[^A-ZÅÄÖ0-9- ]/g, '')); // remove non-alpha numeric characters, except space
      if (value) setShowButton(true);
      else setShowButton(false);
    },
    [setFieldValue]
  );

  const onClick = useCallback(async () => {
    const translate = getTranslate(localize);
    const codeWithoutSpaces = code.replace(/ /g, '');
    if ((codeWithoutSpaces || '').length < 5) {
      setFieldError('code', translate('discount.tooShort'));
      return;
    }
    const discount = await api.checkDiscountCode(codeWithoutSpaces, serviceCode);
    if (typeof discount === 'string') {
      setFieldTouched('code', true, false);
      setFieldError('code', translate(`discount.${discount}`));
      return;
    }
    setFieldValue('discount', {
      code: codeWithoutSpaces,
      amount: discount.amount,
      percentage: discount.percentage,
    });
  }, [
    code,
    serviceCode,
    setFieldValue,
    setFieldError,
    setFieldTouched,
    localize,
  ]);

  const onEnter = useCallback(
    e => {
      if (e.key === 'Enter') {
        e.preventDefault();
        onClick();
      }
    },
    [onClick]
  );

  return (
    <div
      sx={{
        position: 'relative',
        display: 'flex',
        mt: 4,
        maxWidth: ['100%', 360],
      }}
    >
      <Box sx={{ width: '100%', flex: 1 }}>
        <FormField
          name="code"
          onChange={onInputChange}
          onKeyDown={onEnter}
          label={translate('discount.add')}
          sx={{ pr: 4 }}
        />
      </Box>
      {showButton && (
        <Button
          sx={{ alignSelf: 'flex-start', mt: 24, ml: 2 }}
          type="button"
          onClick={onClick}
        >
          {isSubmitting ? (
            <Box sx={{ position: 'relative' }}>
              <Spinner color="white" />
              <span
                sx={{ visibility: 'hidden', opacity: 0 }}
                aria-hidden="true"
              >
                {translate('discount.redeem')}
              </span>
            </Box>
          ) : (
            translate('discount.redeem')
          )}
        </Button>
      )}
    </div>
  );
};

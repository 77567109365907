import { jsx } from 'theme-ui';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { PaymentSelection } from '../components/Payment';
import { createOrder } from './order';

export default ({ state, service, discountCode, goBack }) => {
  const { shipmentNumber, direction } = state || {};
  const dispatch = useDispatch();
  const [isProcessing, setProcessing] = useState(false);

  const onPayButton = async paymentOptions => {
    if (isProcessing) {
      return;
    }
    try {
      setProcessing(true);
      await dispatch(createOrder(direction, shipmentNumber, service, paymentOptions, discountCode));
      setProcessing(false);
    } catch (error) {
      //
    }
  };

  return <PaymentSelection onPayButton={onPayButton} onBackClick={goBack} />;
};

/** @jsx jsx */
import { jsx, Box } from 'theme-ui';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import SelectTime from '../storage/SelectTime';
import StorageConfirmation from '../storage/StorageConfirmation';
import Payment from '../storage/Payment';
import { isBrowser } from '../utils';
import { SendParcelWidget, TrackingSearchWidget } from '../components/widgets';
import AccountLayout from '../account/AccountLayout';
import * as analytics from '../utils/analytics';

export default ({ location: { state } = {}, pageContext }) => {
  analytics.usePageCategory('paketit');
  const translate = getTranslate(useSelector(state => state.localize));
  const [page, setPage] = useState(0);
  const [service, setService] = useState();
  const [discountCode, setDiscountCode] = useState();
  const selectService = useCallback((service, discountCode) => {
    setService(service);
    setDiscountCode(discountCode);
    setPage(1);
  }, []);
  const goBack = useCallback(() => {
    setPage(0);
  }, [setPage]);

  const stateKeys = Object.keys(state || {});
  const hasState = stateKeys.includes('shipmentNumber') || stateKeys.includes('order');

  if (!hasState) {
    try {
      state =
        isBrowser &&
        JSON.parse(window.sessionStorage.getItem('buyStorageTime'));
    } catch (err) {
      // no-op
    }
  } else if (!state.saved) {
    // remember shipment if user refresh browser
    isBrowser &&
      window.sessionStorage.setItem('buyStorageTime', JSON.stringify(state));
    state.saved = true;
  }

  return (
    <AccountLayout
      title={translate('storage.title')}
      paths={pageContext.paths}
      locale={pageContext.locale || 'en'}
      checkShopDisturbance={true}
      sidebar={
        <Box>
          <SendParcelWidget sidebar sx={{ mb: 3 }} />
          <TrackingSearchWidget sidebar />
        </Box>
      }
    >
      {state && state.order ? (
        <StorageConfirmation state={state} />
      ) : page === 0 ? (
        <SelectTime
          state={state}
          service={service}
          selectService={selectService}
        />
      ) : page === 1 ? (
        <Payment
          state={state}
          service={service}
          discountCode={discountCode}
          goBack={goBack}
        />
      ) : null}
    </AccountLayout>
  );
};

/** @jsx jsx */
import { jsx, Flex, Box, Container } from 'theme-ui';
import { useSelector, useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import { locNavigate } from '../state/session';
import * as api from '../utils/api';

import Layout from '../components/layout';
import AccountMenu from './AccountMenu';

const AccountLayout = ({ title, locale, paths, sidebar, ...props }) => {
  const dispatch = useDispatch();
  const hasUser = useSelector(state => !!(state.session && state.session.user));

  useEffect(() => {
    (async () => {
      if (!hasUser) {
        if (!api.getToken()) {
          const currentPath = window.location.pathname;
          return dispatch(locNavigate('/login', `?afterLogin=${currentPath}`));
        } else {
          return;
        }
      }
    })();
  }, [hasUser, dispatch]);

  return (
    <Layout
      title={title}
      locale={locale}
      paths={paths}
      darkMode={true}
      showNavigation={false}
      footer={false}
      {...props}
    >
      <Container variant="wide">
        <Flex sx={{ flex: 1, flexDirection: ['column', null, 'row'] }}>
          <AccountMenu />
          <Flex
            sx={{
              width: '100%',
              position: 'relative',
              justifyContent: 'space-between',
              flexWrap: ['wrap', null, null, null, 'nowrap'],
            }}
          >
            <Box sx={{ mr: [null, null, null, null, 4], flex: 'auto' }}>
              {props.children}
            </Box>
            {sidebar && (
              <Box
                sx={{
                  flex: 'none',
                  py: [3, 4],
                  width: ['100%', null, 360, 400],
                }}
              >
                {sidebar}
              </Box>
            )}
          </Flex>
        </Flex>
      </Container>
    </Layout>
  );
};
export default AccountLayout;

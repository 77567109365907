/** @jsx jsx */
import { jsx, Flex, Box, Button } from 'theme-ui';
import { getTranslate } from 'react-localize-redux';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import Link from '../components/Link';
import {
  ReceivedParcelIcon,
  SentParcelIcon,
  MultiParcelIcon,
  BusTicketIcon,
  TravelCardIcon,
  FavouriteRoutesIcon,
  UserSettingsIcon,
  ChangePasswordIcon,
  ChevronIcon,
  TrashIcon,
} from '../components/Icon';

const NavLink = props => (
  <Flex
    as={Link}
    partiallyActive={true}
    {...props}
    sx={{
      position: 'relative',
      p: 2,
      mx: -2,
      borderRadius: 1,
      color: 'text',
      ':hover': {
        color: 'primary',
      },
      '&[aria-current="page"]': {
        bg: 'primary',
        color: 'white',
        '> svg': {
          color: 'currentColor',
        },
      },
      '> svg': {
        mr: 3,
        color: 'primary',
      },
    }}
  >
    {props.icon && props.icon}
    {props.children}
  </Flex>
);

const Heading = ({ children, ...props }) => (
  <Box sx={{ fontWeight: 'medium', mt: 3, mb: 2 }} {...props}>
    {children}
  </Box>
);

export default () => {
  const translate = getTranslate(useSelector(state => state.localize));
  const [menu, setMenu] = useState(false);
  const showArchiveButton = useSelector(state => Object.keys(state.shipments.archived).length > 0);
  const { user, isLoading } = useSelector(state => state.session);

  if (!isLoading && !user?.marketingOptedIn) return null;

  return (
    <Box
      sx={{
        width: ['auto', null, 320],
        pr: [3, null, 4],
        pl: [3, null, 0],
        mr: [-3, null, 4],
        ml: [-3, null, 0],
        borderRightStyle: [null, null, 'solid'],
        borderRightWidth: [null, null, '1px'],
        borderBottomStyle: ['solid', null, 'none'],
        borderBottomWidth: ['1px', null, '0'],
        borderColor: 'border',
      }}
    >
      <Button
        variant="plain"
        onClick={() => setMenu(!menu)}
        sx={{
          width: '100%',
          display: ['flex', null, 'none'],
          justifyContent: 'space-between',
          alignItems: 'center',
          color: 'text',
          py: 3,
        }}
      >
        {translate('account.myPages.title')}
        <ChevronIcon
          sx={{
            width: 16,
            height: 16,
            transform: `rotate(${menu ? '-90deg' : '90deg'})`,
          }}
        />
      </Button>
      <Box
        sx={{
          display: [menu ? 'block' : 'none', null, 'block'],
          py: [3, 4],
          borderTop: ['1px solid', null, 'none'],
          borderColor: 'border',
        }}
      >
        <Heading sx={{ mt: 0 }}>{translate('account.navigation.myParcels')}</Heading>
        <NavLink to="/my-pages/incoming-parcels" icon={<ReceivedParcelIcon />}>
          {translate('account.navigation.receivedParcels')}
        </NavLink>
        <NavLink to="/my-pages/sent-parcels" icon={<SentParcelIcon />}>
          {translate('account.navigation.sentParcels')}
        </NavLink>
        <NavLink to="/my-pages/multi-parcels" icon={<MultiParcelIcon />}>
          {translate('account.navigation.multiParcels')}
        </NavLink>
        {showArchiveButton && (
          <NavLink to="/my-pages/parcel-archive" icon={<TrashIcon />}>
            {translate('account.navigation.archive')}
          </NavLink>
        )}
        <Heading>{translate('account.navigation.myTrips')}</Heading>
        <NavLink to="/my-pages/my-tickets" icon={<BusTicketIcon />}>
          {translate('account.navigation.tickets')}
        </NavLink>
        <NavLink to="/my-pages/travel-cards" icon={<TravelCardIcon />}>
          {translate('account.navigation.travelCards')}
        </NavLink>
        <NavLink to="/my-pages/favorite-trips" icon={<FavouriteRoutesIcon />}>
          {translate('account.navigation.favouriteRoutes')}
        </NavLink>
        <Heading>{translate('account.header')}</Heading>
        <NavLink to="/my-account" icon={<UserSettingsIcon />}>
          {translate('account.navigation.settings')}
        </NavLink>
        <NavLink to="/change-password" icon={<ChangePasswordIcon />}>
          {translate('account.navigation.changePassword')}
        </NavLink>
        {/**
      <NavLink
        onClick={e => {
          dispatch(logout());
          e.preventDefault();
        }}
        children={translate('page.logout')}
      />
       */}
      </Box>
    </Box>
  );
};

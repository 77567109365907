/** @jsx jsx */
import { jsx, Flex, Box, Styled } from 'theme-ui';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import moment from 'moment';
import FullHeightColumn from '../components/FullHeightColumn';
import Link from '../components/Link';
import * as api from '../utils/api';
import { capitalize } from '../utils/string';

export default ({ state }) => {
  const { order } = state || {};
  const translate = getTranslate(useSelector(state => state.localize));
  const dispatch = useDispatch();

  const { shipmentNumber, storedUntil, language, direction = 'received' } = order;
  const { serviceCode, service: { days } = {} } = order.storageTime;
  const { placeId, placeName } = order.pickupPoint || {};
  const { senderName } = order.sender || {};
  const { receiverName } = order.receiver || {};
  const [pickupPoint, setPickupPoint] = useState();
  useEffect(() => {
    if (pickupPoint || !placeId) {
      return;
    }
    setPickupPoint({}); // prevent double api call if this effect is executed twice
    (async () => {
      const response = await api.getServicePointById(placeId, [], language);
      if (response[0]) {
        setPickupPoint(response[0]);
      }
    })();
  }, [pickupPoint, setPickupPoint, placeId, language]);

  let pickupPointDescription = capitalize(placeName);
  if (pickupPoint && pickupPoint.officeName) {
    const { officeCode, officeName, officeStreetAddress, officePostalCode, officeCity } = pickupPoint;
    pickupPointDescription = `${officeName}, ${officeStreetAddress}, ${officePostalCode} ${officeCity}`;
  }
  const params = {
    shipmentNumber,
    sender: senderName,
    receiver: receiverName,
    pickupPoint: pickupPointDescription,
    storedUntil: moment(storedUntil).format('D.M.YYYY'),
  };

  return (
    <FullHeightColumn>
      <Styled.h1 sx={{ color: 'secondary', mt: 0 }}>{translate('storage.confirmation.title')}</Styled.h1>
      <p>
        {direction === 'sent'
          ? translate('storage.confirmation.receiverDescription', params)
          : translate('storage.confirmation.senderDescription', params)}
      </p>
      <p>
        <Link to={direction === 'sent' ? '/my-pages/sent-parcels' : '/my-pages/incoming-parcels'}>
          {translate('storage.confirmation.return')}
        </Link>
      </p>
    </FullHeightColumn>
  );
};

/** @jsx jsx */
import { jsx, Flex, Box, Styled } from 'theme-ui';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { withFormik } from 'formik';
import * as yup from 'yup';
import moment from 'moment';
import { Button } from '../components';
import FullHeightColumn from '../components/FullHeightColumn';
import { Form, Field } from 'formik';
import RadioFieldGroup from '../components/RadioFieldGroup';
import RadioField from '../components/RadioField';
import Spinner from '../components/Spinner';
import { getCards } from '../state/session';
import { formatPrice } from '../utils/formatters';
import * as api from '../utils/api';
import { createOrder } from './order';
import AddDiscountCode from './AddDiscountCode';

const DiscountRow = ({ code, amount, discountedPrice }) => {
  const translate = getTranslate(useSelector(state => state.localize));
  return (
    <div>
      {translate('discount.code')} <span sx={{ fontWeight: 'medium' }}>{code}</span> {formatPrice(-amount)}
      {(discountedPrice || discountedPrice === 0) && (
        <>
          <br />
          {translate('discount.afterPrice')}{' '}
          <span sx={{ fontSize: 3, fontWeight: 'medium', color: 'primary' }}>{formatPrice(discountedPrice)}</span>.
        </>
      )}
    </div>
  );
};

const TimeForm = ({
  shipmentNumber,
  storedUntil,
  location,
  services,
  values,
  errors = {},
  setFieldValue,
  isSubmitting,
  handleSubmit,
}) => {
  const translate = getTranslate(useSelector(state => state.localize));
  const service = (services || []).find(s => s.serviceCode === values.storageTime);
  const { discount } = values;
  const addDiscountVisible = !discount;
  const discountAmount =
    (discount &&
      service &&
      (discount.amount
        ? Math.min(service.priceVatIncluded, discount.amount)
        : +((service.priceVatIncluded * discount.percentage) / 100).toFixed(2))) ||
    0;
  const discountedPrice = service && service.priceVatIncluded - discountAmount;
  const requiresPayment = !service || +discountedPrice > 0;
  const storageTimeNotAvailable = Array.isArray(services) && services.length === 0;

  return (
    <Form
      sx={{
        width: '100%',
        my: 2,
      }}
    >
      <RadioFieldGroup id="storageTime" error={errors.storageTime}>
        {!services && (
          <div sx={{ position: 'absolute', width: '100px' }}>
            <Spinner />
          </div>
        )}
        {storageTimeNotAvailable && <div sx={{ fontWeight: 'medium' }}>{translate('storage.notAvailable')}</div>}
        {services && (
          <Box
            sx={{
              border: '2px solid',
              borderColor: 'border',
              borderRadius: 2,
              overflow: 'hidden',
            }}
          >
            {(services || []).map(service => {
              const { days, serviceCode, priceVatIncluded, normalPrice } = service;
              const price = formatPrice(priceVatIncluded);
              const date = moment(storedUntil)
                .add(days, 'days')
                .format('D.M.YYYY');
              const description = (
                <span
                  dangerouslySetInnerHTML={{
                    __html: translate('storage.buyUntil', {
                      date: `<b>${date}</b>`,
                    }),
                  }}
                />
              );
              return (
                <Box
                  key={serviceCode}
                  sx={{
                    borderBottom: '1px solid',
                    borderBottomColor: 'border',
                    py: 1,
                    px: 2,
                    ':hover': {
                      bg: 'blueLighter',
                      cursor: 'pointer',
                    },
                  }}
                >
                  <Field
                    key={serviceCode}
                    sx={{
                      width: '100%',
                    }}
                    component={RadioField}
                    name="storageTime"
                    id={serviceCode}
                    label={
                      <Flex
                        sx={{
                          alignSelf: 'center',
                          justifyContent: 'space-between',
                          flex: '1 !important',
                        }}
                      >
                        <Box sx={{ flexShrink: '1 !important' }}>{description}</Box>
                        <Box sx={{ flex: 'none', pr: 2 }}>
                          {/*translate('storage.price')*/}
                          <span sx={{ fontWeight: 'heading', color: 'primary' }}>{price}</span>
                          {normalPrice &&
                            translate('storage.normalPrice', {
                              price: formatPrice(normalPrice),
                            })}
                        </Box>
                      </Flex>
                    }
                  />
                </Box>
              );
            })}
          </Box>
        )}
      </RadioFieldGroup>

      {addDiscountVisible && <AddDiscountCode serviceCode="SP1" />}
      {discount && <DiscountRow code={discount.code} amount={discountAmount} discountedPrice={discountedPrice} />}

      <p>{translate('storage.confirm', { shipmentNumber, location })}</p>
      <Flex
        sx={{
          justifyContent: ['flex-end', null, 'flex-start'],
          mt: 4,
        }}
      >
        <Button type="submit" onClick={handleSubmit} disabled={isSubmitting || storageTimeNotAvailable}>
          {requiresPayment ? translate('buyParcel.pay') : translate('buyParcel.continue')}
        </Button>
      </Flex>
    </Form>
  );
};

const storageSchema = translate =>
  yup.object().shape({
    storageTime: yup.string().required(translate('form.requiredField')),
    code: yup.string().min(3, translate('discount.tooShort')),
    discount: yup
      .object({
        code: yup.string(),
        amount: yup.string(),
      })
      .nullable(),
  });

const TimeFormik = withFormik({
  mapPropsToValues: ({ storageTime }) => ({
    storageTime,
    code: '',
    discount: null,
  }),

  validationSchema: ({ translate }) => storageSchema(translate),

  handleSubmit: (values, { props: { onSubmit }, ...actions }) => {
    return onSubmit(values, actions);
  },

  displayName: 'TimeForm',
})(TimeForm);

export default ({ service, selectService, state }) => {
  const { direction, shipmentNumber, receiver, sender, destinationPlaceName, pickupPoint, storedUntil } = state || {};
  const dispatch = useDispatch();
  const translate = getTranslate(useSelector(state => state.localize));
  const [services, setServices] = useState();
  const [isProcessing, setProcessing] = useState(false);

  useEffect(() => {
    if (services) return;
    (async () => {
      const response = await api.getStorageTimeOptions(shipmentNumber);
      setServices(response.services || []);
    })();
  }, [services, shipmentNumber]);

  const onBackClick = useCallback(() => window.history.back(), []);

  const onSubmit = useCallback(
    async values => {
      if (!services) {
        return;
      }
      const service = services.find(s => s.serviceCode === values.storageTime);
      const { discount } = values;
      const discountAmount =
        (discount &&
          service &&
          (discount.amount
            ? Math.min(service.priceVatIncluded, discount.amount)
            : +((service.priceVatIncluded * discount.percentage) / 100).toFixed(2))) ||
        0;
      const discountedPrice = service && service.priceVatIncluded - discountAmount;
      const requiresPayment = !service || +discountedPrice > 0;
      if (requiresPayment) {
        selectService(values.storageTime, (values.discount || {}).code);
      } else {
        if (isProcessing) {
          return;
        }
        try {
          const { code } = values.discount || {};
          setProcessing(true);
          await dispatch(createOrder(direction, shipmentNumber, service.serviceCode, { paymentMethod: 'FREE' }, code));
          setProcessing(false);
        } catch (error) {
          //
        }
      }
    },
    [selectService, services, isProcessing, setProcessing, dispatch, shipmentNumber]
  );

  let location = destinationPlaceName;
  if (pickupPoint) {
    const { officeName, officeStreetAddress, officePostalCode, officeCity } = pickupPoint;
    location = `${officeName}, ${officeStreetAddress}, ${officePostalCode} ${officeCity}`;
  }

  // preload user saved payment cards
  const user = useSelector(state => state.session.user);
  useEffect(() => {
    if (user && !user.cards) {
      dispatch(getCards());
    }
  }, [user, dispatch]);

  const formProps = {
    shipmentNumber,
    storedUntil,
    services,
    location,
    onSubmit,
    translate,
  };

  return (
    <FullHeightColumn>
      <Box>
        <Button onClick={onBackClick} variant="plain" sx={{ color: 'primary' }}>
          {translate('backButton')}
        </Button>
      </Box>

      <Styled.h1 sx={{ color: 'secondary', mt: 0 }}>{translate('storage.title')}</Styled.h1>
      <p sx={{ mb: 0 }}>
        {direction === 'sent'
          ? translate('storage.receiverDescription', { shipmentNumber, receiver })
          : translate('storage.senderDescription', { shipmentNumber, sender })}
      </p>
      <p
        dangerouslySetInnerHTML={{
          __html: translate('storage.pickupPoint', {
            location,
            date: `<b>${moment(storedUntil).format('D.M.YYYY')}</b>`,
          }),
        }}
      />

      <Flex
        sx={{
          flex: ['auto', null, 'none'],
          flexDirection: 'column',
          justifyContent: 'flex-end',
        }}
      >
        <TimeFormik {...formProps} />
      </Flex>
    </FullHeightColumn>
  );
};

import * as api from '../utils/api';
import getLanguage from '../utils/getLanguage';
import { showNotification } from '../state/notifications';
import { setOrderId } from '../utils/order';
import { isBrowser } from '../utils';

export const createOrder = (direction, shipmentNumber, serviceCode, paymentOptions, discountCode) => async (dispatch, getState) => {
  try {
    const language = getLanguage(getState());
    const response = await api.createStorageTimeOrder(direction, shipmentNumber, paymentOptions, language, serviceCode, discountCode);
    const { redirectUrl, orderId, transactionId } = response;
    if (redirectUrl) {
      setOrderId(orderId, transactionId);
      isBrowser && window.location.assign(redirectUrl);
      return;
    }
  } catch (error) {
    console.warn('Failed to create storage time order:', error);
    dispatch(showNotification('genericApiError'));
  }
};
